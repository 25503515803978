import { computed } from 'vue';
export default function useCacheOptions(_values, options) {
    const optionMap = computed(() => {
        const map = new Map();
        options.value.forEach((item) => {
            const { data: { value }, } = item;
            map.set(value, item);
        });
        return map;
    });
    const getValueOption = (vals) => vals.map(value => optionMap.value.get(value)).filter(Boolean);
    return getValueOption;
}
