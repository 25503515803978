var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import animation from '../_util/openAnimation';
import { getOptionProps, getComponent, isValidElement, getSlot } from '../_util/props-util';
import { cloneElement } from '../_util/vnode';
import VcCollapse from '../vc-collapse';
import RightOutlined from '@ant-design/icons-vue/RightOutlined';
import { defaultConfigProvider } from '../config-provider';
import PropTypes from '../_util/vue-types';
import { tuple } from '../_util/type';
export default defineComponent({
    name: 'ACollapse',
    inheritAttrs: false,
    props: {
        prefixCls: PropTypes.string,
        activeKey: { type: [Array, Number, String] },
        defaultActiveKey: { type: [Array, Number, String] },
        accordion: PropTypes.looseBool,
        destroyInactivePanel: PropTypes.looseBool,
        bordered: PropTypes.looseBool.def(true),
        expandIcon: PropTypes.func,
        openAnimation: PropTypes.object.def(animation),
        expandIconPosition: PropTypes.oneOf(tuple('left', 'right')).def('left'),
        'onUpdate:activeKey': PropTypes.func,
        onChange: PropTypes.func,
    },
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    methods: {
        renderExpandIcon(panelProps = {}, prefixCls) {
            const expandIcon = getComponent(this, 'expandIcon', panelProps);
            const icon = expandIcon || <RightOutlined rotate={panelProps.isActive ? 90 : undefined}/>;
            return isValidElement(Array.isArray(expandIcon) ? icon[0] : icon)
                ? cloneElement(icon, {
                    class: `${prefixCls}-arrow`,
                })
                : icon;
        },
        handleChange(activeKey) {
            this.$emit('update:activeKey', activeKey);
            this.$emit('change', activeKey);
        },
    },
    render() {
        const { prefixCls: customizePrefixCls, bordered, expandIconPosition } = this;
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('collapse', customizePrefixCls);
        const _a = this.$attrs, { class: className } = _a, restAttrs = __rest(_a, ["class"]);
        const collapseClassName = {
            [className]: className,
            [`${prefixCls}-borderless`]: !bordered,
            [`${prefixCls}-icon-position-${expandIconPosition}`]: true,
        };
        const rcCollapeProps = Object.assign(Object.assign(Object.assign(Object.assign({}, getOptionProps(this)), { prefixCls, expandIcon: (panelProps) => this.renderExpandIcon(panelProps, prefixCls), class: collapseClassName }), restAttrs), { onChange: this.handleChange });
        return <VcCollapse {...rcCollapeProps}>{getSlot(this)}</VcCollapse>;
    },
});
