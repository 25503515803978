function notEmpty(val) {
    return val !== undefined && val !== null;
}
const Cell = props => {
    const { itemPrefixCls, component, span, bordered, label, content, colon } = props;
    const Component = component;
    if (bordered) {
        return (<Component class={[
                {
                    [`${itemPrefixCls}-item-label`]: notEmpty(label),
                    [`${itemPrefixCls}-item-content`]: notEmpty(content),
                },
            ]} colSpan={span}>
        {notEmpty(label) ? label : content}
      </Component>);
    }
    return (<Component class={[`${itemPrefixCls}-item`]} colSpan={span}>
      {label && (<span class={[
                `${itemPrefixCls}-item-label`,
                {
                    [`${itemPrefixCls}-item-no-colon`]: !colon,
                },
            ]}>
          {label}
        </span>)}
      {content && <span class={`${itemPrefixCls}-item-content`}>{content}</span>}
    </Component>);
};
export default Cell;
