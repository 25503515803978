var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import { getOptionProps, getComponent, getSlot } from '../_util/props-util';
import VcCollapse from '../vc-collapse';
import { defaultConfigProvider } from '../config-provider';
import PropTypes from '../_util/vue-types';
export default defineComponent({
    name: 'ACollapsePanel',
    inheritAttrs: false,
    props: {
        openAnimation: PropTypes.object,
        prefixCls: PropTypes.string,
        header: PropTypes.VNodeChild,
        headerClass: PropTypes.string,
        showArrow: PropTypes.looseBool,
        isActive: PropTypes.looseBool,
        destroyInactivePanel: PropTypes.looseBool,
        disabled: PropTypes.looseBool,
        accordion: PropTypes.looseBool,
        forceRender: PropTypes.looseBool,
        expandIcon: PropTypes.func,
        extra: PropTypes.VNodeChild,
        panelKey: PropTypes.VNodeChild,
    },
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    render() {
        const { prefixCls: customizePrefixCls, showArrow = true } = this;
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('collapse', customizePrefixCls);
        const _a = this.$attrs, { class: className } = _a, restAttrs = __rest(_a, ["class"]);
        const collapsePanelClassName = {
            [className]: className,
            [`${prefixCls}-no-arrow`]: !showArrow,
        };
        const rcCollapePanelProps = Object.assign(Object.assign(Object.assign({}, getOptionProps(this)), { header: getComponent(this, 'header'), prefixCls, extra: getComponent(this, 'extra'), class: collapsePanelClassName }), restAttrs);
        return <VcCollapse.Panel {...rcCollapePanelProps}>{getSlot(this)}</VcCollapse.Panel>;
    },
});
