import { defineComponent, inject, provide } from 'vue';
import Select, { SelectProps } from '../select';
import Input from '../input';
import InputElement from './InputElement';
import PropTypes from '../_util/vue-types';
import { defaultConfigProvider } from '../config-provider';
import { getComponent, getOptionProps, isValidElement, getSlot } from '../_util/props-util';
import Omit from 'omit.js';
import warning from '../_util/warning';
const { Option, OptGroup } = Select;
function isSelectOptionOrSelectOptGroup(child) {
    var _a, _b;
    return ((_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.isSelectOption) || ((_b = child === null || child === void 0 ? void 0 : child.type) === null || _b === void 0 ? void 0 : _b.isSelectOptGroup);
}
const AutoCompleteProps = Object.assign(Object.assign({}, SelectProps()), { dataSource: PropTypes.array, dropdownMenuStyle: PropTypes.style, optionLabelProp: PropTypes.string, dropdownMatchSelectWidth: PropTypes.looseBool });
const AutoComplete = defineComponent({
    name: 'AAutoComplete',
    inheritAttrs: false,
    props: Object.assign(Object.assign({}, AutoCompleteProps), { prefixCls: PropTypes.string.def('ant-select'), showSearch: PropTypes.looseBool.def(false), transitionName: PropTypes.string.def('slide-up'), choiceTransitionName: PropTypes.string.def('zoom'), autofocus: PropTypes.looseBool, backfill: PropTypes.looseBool, optionLabelProp: PropTypes.string.def('children'), filterOption: PropTypes.oneOfType([PropTypes.looseBool, PropTypes.func]).def(false), defaultActiveFirstOption: PropTypes.looseBool.def(true) }),
    emits: ['change', 'select', 'focus', 'blur'],
    Option: Object.assign(Object.assign({}, Option), { name: 'AAutoCompleteOption' }),
    OptGroup: Object.assign(Object.assign({}, OptGroup), { name: 'AAutoCompleteOptGroup' }),
    setup(props, { slots }) {
        warning(!('dataSource' in props || 'dataSource' in slots), 'AutoComplete', '`dataSource` is deprecated, please use `options` instead.');
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
            popupRef: null,
            select: null,
        };
    },
    created() {
        provide('savePopupRef', this.savePopupRef);
    },
    methods: {
        savePopupRef(ref) {
            this.popupRef = ref;
        },
        saveSelect(node) {
            this.select = node;
        },
        getInputElement() {
            const children = getSlot(this);
            const element = children.length ? children[0] : <Input lazy={false}/>;
            return <InputElement {...element.props}>{element}</InputElement>;
        },
        focus() {
            if (this.select) {
                this.select.focus();
            }
        },
        blur() {
            if (this.select) {
                this.select.blur();
            }
        },
    },
    render() {
        const { size, prefixCls: customizePrefixCls, dataSource } = this;
        let optionChildren;
        const { getPrefixCls } = this.configProvider;
        const prefixCls = getPrefixCls('select', customizePrefixCls);
        const { class: className } = this.$attrs;
        const cls = {
            [className]: !!className,
            [`${prefixCls}-lg`]: size === 'large',
            [`${prefixCls}-sm`]: size === 'small',
            [`${prefixCls}-show-search`]: true,
            [`${prefixCls}-auto-complete`]: true,
        };
        const childArray = getSlot(this, 'dataSource');
        if (childArray.length && isSelectOptionOrSelectOptGroup(childArray[0])) {
            optionChildren = childArray;
        }
        else {
            optionChildren = dataSource
                ? dataSource.map((item) => {
                    if (isValidElement(item)) {
                        return item;
                    }
                    switch (typeof item) {
                        case 'string':
                            return (<Option key={item} value={item}>
                    {item}
                  </Option>);
                        case 'object':
                            return (<Option key={item.value} value={item.value}>
                    {item.text}
                  </Option>);
                        default:
                            throw new Error('AutoComplete[dataSource] only supports type `string[] | Object[]`.');
                    }
                })
                : [];
        }
        const selectProps = Object.assign(Object.assign(Object.assign({}, Omit(getOptionProps(this), ['dataSource', 'optionLabelProp'])), this.$attrs), { mode: Select.SECRET_COMBOBOX_MODE_DO_NOT_USE, 
            // optionLabelProp,
            getInputElement: this.getInputElement, notFoundContent: getComponent(this, 'notFoundContent'), 
            // placeholder: '',
            class: cls, ref: this.saveSelect });
        return <Select {...selectProps}>{optionChildren}</Select>;
    },
});
/* istanbul ignore next */
AutoComplete.install = function (app) {
    app.component(AutoComplete.name, AutoComplete);
    app.component(AutoComplete.Option.name, AutoComplete.Option);
    app.component(AutoComplete.OptGroup.name, AutoComplete.OptGroup);
    return app;
};
export default AutoComplete;
