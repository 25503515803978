import { reactive, provide, defineComponent, watch } from 'vue';
import PropTypes from '../_util/vue-types';
import defaultRenderEmpty from './renderEmpty';
import LocaleProvider, { ANT_MARK } from '../locale-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { withInstall } from '../_util/type';
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
const ConfigProvider = defineComponent({
    name: 'AConfigProvider',
    props: {
        getTargetContainer: {
            type: Function,
        },
        getPopupContainer: {
            type: Function,
        },
        prefixCls: String,
        getPrefixCls: {
            type: Function,
        },
        renderEmpty: {
            type: Function,
        },
        transformCellText: {
            type: Function,
        },
        csp: {
            type: Object,
        },
        autoInsertSpaceInButton: PropTypes.looseBool,
        locale: {
            type: Object,
        },
        pageHeader: {
            type: Object,
        },
        componentSize: {
            type: Object,
        },
        direction: {
            type: String,
        },
        space: {
            type: [String, Number],
        },
        virtual: PropTypes.looseBool,
        dropdownMatchSelectWidth: PropTypes.looseBool,
    },
    setup(props, { slots }) {
        const getPrefixCls = (suffixCls, customizePrefixCls) => {
            const { prefixCls = 'ant' } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            return suffixCls ? `${prefixCls}-${suffixCls}` : prefixCls;
        };
        const renderEmptyComponent = (name) => {
            const renderEmpty = (props.renderEmpty ||
                slots.renderEmpty ||
                defaultRenderEmpty);
            return renderEmpty(name);
        };
        const getPrefixClsWrapper = (suffixCls, customizePrefixCls) => {
            const { prefixCls } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            const mergedPrefixCls = prefixCls || getPrefixCls('');
            return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
        };
        const configProvider = reactive(Object.assign(Object.assign({}, props), { getPrefixCls: getPrefixClsWrapper, renderEmpty: renderEmptyComponent }));
        watch(props, () => {
            Object.assign(configProvider, props);
        });
        provide('configProvider', configProvider);
        const renderProvider = (legacyLocale) => {
            var _a;
            return (<LocaleProvider locale={props.locale || legacyLocale} ANT_MARK__={ANT_MARK}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </LocaleProvider>);
        };
        return () => (<LocaleReceiver children={(_, __, legacyLocale) => renderProvider(legacyLocale)}/>);
    },
});
export const defaultConfigProvider = {
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return `ant-${suffixCls}`;
    },
    renderEmpty: defaultRenderEmpty,
};
export default withInstall(ConfigProvider);
