import { tuple } from '../_util/type';
import { isPresetColor } from './utils';
import { defaultConfigProvider } from '../config-provider';
import { inject } from 'vue';
import PropTypes from '../_util/vue-types';
const Ribbon = (props, { attrs, slots }) => {
    var _a, _b;
    const { prefixCls: customizePrefixCls, color, text = (_a = slots.text) === null || _a === void 0 ? void 0 : _a.call(slots), placement = 'end' } = props;
    const { class: className, style } = attrs;
    const children = (_b = slots.default) === null || _b === void 0 ? void 0 : _b.call(slots);
    const { getPrefixCls, direction } = inject('configProvider', defaultConfigProvider);
    const prefixCls = getPrefixCls('ribbon', customizePrefixCls);
    const colorInPreset = isPresetColor(color);
    const ribbonCls = [
        prefixCls,
        `${prefixCls}-placement-${placement}`,
        {
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-color-${color}`]: colorInPreset,
        },
        className,
    ];
    const colorStyle = {};
    const cornerColorStyle = {};
    if (color && !colorInPreset) {
        colorStyle.background = color;
        cornerColorStyle.color = color;
    }
    return (<div class={`${prefixCls}-wrapper`}>
      {children}
      <div class={ribbonCls} style={Object.assign(Object.assign({}, colorStyle), style)}>
        <span class={`${prefixCls}-text`}>{text}</span>
        <div class={`${prefixCls}-corner`} style={cornerColorStyle}/>
      </div>
    </div>);
};
Ribbon.displayName = 'ABadgeRibbon';
Ribbon.inheritAttrs = false;
Ribbon.props = {
    prefix: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.any,
    placement: PropTypes.oneOf(tuple('start', 'end')),
};
export default Ribbon;
