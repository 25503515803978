import { provide, defineComponent, reactive } from 'vue';
import PropTypes from '../_util/vue-types';
import moment from 'moment';
import interopDefault from '../_util/interopDefault';
import { changeConfirmLocale } from '../modal/locale';
import warning from '../_util/warning';
import { getSlot } from '../_util/props-util';
import { withInstall } from '../_util/type';
export const ANT_MARK = 'internalMark';
function setMomentLocale(locale) {
    if (locale && locale.locale) {
        interopDefault(moment).locale(locale.locale);
    }
    else {
        interopDefault(moment).locale('en');
    }
}
const LocaleProvider = defineComponent({
    name: 'ALocaleProvider',
    props: {
        locale: {
            type: Object,
        },
        ANT_MARK__: PropTypes.string,
    },
    setup(props) {
        warning(props.ANT_MARK__ === ANT_MARK, 'LocaleProvider', '`LocaleProvider` is deprecated. Please use `locale` with `ConfigProvider` instead');
        const state = reactive({
            antLocale: Object.assign(Object.assign({}, props.locale), { exist: true }),
            ANT_MARK__: ANT_MARK,
        });
        provide('localeData', state);
        return { state };
    },
    watch: {
        locale(val) {
            this.state.antLocale = Object.assign(Object.assign({}, val), { exist: true });
            setMomentLocale(val);
            changeConfirmLocale(val && val.Modal);
        },
    },
    created() {
        const { locale } = this;
        setMomentLocale(locale);
        changeConfirmLocale(locale && locale.Modal);
    },
    beforeUnmount() {
        changeConfirmLocale();
    },
    render() {
        return getSlot(this);
    },
});
/* istanbul ignore next */
LocaleProvider.install = function (app) {
    app.component(LocaleProvider.name, LocaleProvider);
    return app;
};
export default withInstall(LocaleProvider);
