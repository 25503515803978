var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject, provide, computed } from 'vue';
import PropTypes from '../_util/vue-types';
import classNames from '../_util/classNames';
import warning from '../_util/warning';
import FormItem from './FormItem';
import { getSlot } from '../_util/props-util';
import { defaultConfigProvider } from '../config-provider';
import { getNamePath, containsNamePath } from './utils/valueUtil';
import { defaultValidateMessages } from './utils/messages';
import { allPromiseFinish } from './utils/asyncUtil';
import { toArray } from './utils/typeUtil';
import isEqual from 'lodash-es/isEqual';
import scrollIntoView from 'scroll-into-view-if-needed';
import initDefaultProps from '../_util/props-util/initDefaultProps';
import { tuple } from '../_util/type';
export const formProps = {
    layout: PropTypes.oneOf(tuple('horizontal', 'inline', 'vertical')),
    labelCol: { type: Object },
    wrapperCol: { type: Object },
    colon: PropTypes.looseBool,
    labelAlign: PropTypes.oneOf(tuple('left', 'right')),
    prefixCls: PropTypes.string,
    hideRequiredMark: PropTypes.looseBool,
    model: PropTypes.object,
    rules: { type: Object },
    validateMessages: PropTypes.object,
    validateOnRuleChange: PropTypes.looseBool,
    // 提交失败自动滚动到第一个错误字段
    scrollToFirstError: { type: [Boolean, Object] },
    onSubmit: PropTypes.func,
    onFinish: PropTypes.func,
    onFinishFailed: PropTypes.func,
    name: PropTypes.string,
    validateTrigger: { type: [String, Array] },
};
function isEqualName(name1, name2) {
    return isEqual(toArray(name1), toArray(name2));
}
const Form = defineComponent({
    name: 'AForm',
    inheritAttrs: false,
    props: initDefaultProps(formProps, {
        layout: 'horizontal',
        hideRequiredMark: false,
        colon: true,
    }),
    Item: FormItem,
    setup(props) {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
            fields: [],
            form: undefined,
            lastValidatePromise: null,
            vertical: computed(() => props.layout === 'vertical'),
        };
    },
    watch: {
        rules() {
            if (this.validateOnRuleChange) {
                this.validateFields();
            }
        },
    },
    created() {
        provide('FormContext', this);
    },
    methods: {
        addField(field) {
            if (field) {
                this.fields.push(field);
            }
        },
        removeField(field) {
            if (field.fieldName) {
                this.fields.splice(this.fields.indexOf(field), 1);
            }
        },
        handleSubmit(e) {
            e.preventDefault();
            e.stopPropagation();
            this.$emit('submit', e);
            const res = this.validateFields();
            res
                .then(values => {
                this.$emit('finish', values);
            })
                .catch(errors => {
                this.handleFinishFailed(errors);
            });
        },
        getFieldsByNameList(nameList) {
            const provideNameList = !!nameList;
            const namePathList = provideNameList ? toArray(nameList).map(getNamePath) : [];
            if (!provideNameList) {
                return this.fields;
            }
            else {
                return this.fields.filter(field => namePathList.findIndex(namePath => isEqualName(namePath, field.fieldName)) > -1);
            }
        },
        resetFields(name) {
            if (!this.model) {
                warning(false, 'Form', 'model is required for resetFields to work.');
                return;
            }
            this.getFieldsByNameList(name).forEach(field => {
                field.resetField();
            });
        },
        clearValidate(name) {
            this.getFieldsByNameList(name).forEach(field => {
                field.clearValidate();
            });
        },
        handleFinishFailed(errorInfo) {
            const { scrollToFirstError } = this;
            this.$emit('finishFailed', errorInfo);
            if (scrollToFirstError && errorInfo.errorFields.length) {
                let scrollToFieldOptions = {};
                if (typeof scrollToFirstError === 'object') {
                    scrollToFieldOptions = scrollToFirstError;
                }
                this.scrollToField(errorInfo.errorFields[0].name, scrollToFieldOptions);
            }
        },
        validate(...args) {
            return this.validateField(...args);
        },
        scrollToField(name, options = {}) {
            const fields = this.getFieldsByNameList(name);
            if (fields.length) {
                const fieldId = fields[0].fieldId;
                const node = fieldId ? document.getElementById(fieldId) : null;
                if (node) {
                    scrollIntoView(node, Object.assign({ scrollMode: 'if-needed', block: 'nearest' }, options));
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        getFieldsValue(nameList = true) {
            const values = {};
            this.fields.forEach(({ fieldName, fieldValue }) => {
                values[fieldName] = fieldValue;
            });
            if (nameList === true) {
                return values;
            }
            else {
                const res = {};
                toArray(nameList).forEach(namePath => (res[namePath] = values[namePath]));
                return res;
            }
        },
        validateFields(nameList, options) {
            warning(!(nameList instanceof Function), 'Form', 'validateFields/validateField/validate not support callback, please use promise instead');
            if (!this.model) {
                warning(false, 'Form', 'model is required for validateFields to work.');
                return Promise.reject('Form `model` is required for validateFields to work.');
            }
            const provideNameList = !!nameList;
            const namePathList = provideNameList
                ? toArray(nameList).map(getNamePath)
                : [];
            // Collect result in promise list
            const promiseList = [];
            this.fields.forEach(field => {
                // Add field if not provide `nameList`
                if (!provideNameList) {
                    namePathList.push(field.getNamePath());
                }
                // Skip if without rule
                if (!field.getRules().length) {
                    return;
                }
                const fieldNamePath = field.getNamePath();
                // Add field validate rule in to promise list
                if (!provideNameList || containsNamePath(namePathList, fieldNamePath)) {
                    const promise = field.validateRules(Object.assign({ validateMessages: Object.assign(Object.assign({}, defaultValidateMessages), this.validateMessages) }, options));
                    // Wrap promise with field
                    promiseList.push(promise
                        .then(() => ({ name: fieldNamePath, errors: [] }))
                        .catch((errors) => Promise.reject({
                        name: fieldNamePath,
                        errors,
                    })));
                }
            });
            const summaryPromise = allPromiseFinish(promiseList);
            this.lastValidatePromise = summaryPromise;
            const returnPromise = summaryPromise
                .then(() => {
                if (this.lastValidatePromise === summaryPromise) {
                    return Promise.resolve(this.getFieldsValue(namePathList));
                }
                return Promise.reject([]);
            })
                .catch(results => {
                const errorList = results.filter(result => result && result.errors.length);
                return Promise.reject({
                    values: this.getFieldsValue(namePathList),
                    errorFields: errorList,
                    outOfDate: this.lastValidatePromise !== summaryPromise,
                });
            });
            // Do not throw in console
            returnPromise.catch(e => e);
            return returnPromise;
        },
        validateField(...args) {
            return this.validateFields(...args);
        },
    },
    render() {
        const { prefixCls: customizePrefixCls, hideRequiredMark, layout, handleSubmit } = this;
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('form', customizePrefixCls);
        const _a = this.$attrs, { class: className } = _a, restProps = __rest(_a, ["class"]);
        const formClassName = classNames(prefixCls, className, {
            [`${prefixCls}-horizontal`]: layout === 'horizontal',
            [`${prefixCls}-vertical`]: layout === 'vertical',
            [`${prefixCls}-inline`]: layout === 'inline',
            [`${prefixCls}-hide-required-mark`]: hideRequiredMark,
        });
        return (<form onSubmit={handleSubmit} class={formClassName} {...restProps}>
        {getSlot(this)}
      </form>);
    },
});
export default Form;
