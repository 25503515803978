import VcCalendar from '../vc-calendar';
import MonthCalendar from '../vc-calendar/src/MonthCalendar';
import createPicker from './createPicker';
import wrapPicker from './wrapPicker';
import RangePicker from './RangePicker';
import WeekPicker from './WeekPicker';
import { DatePickerProps, MonthPickerProps, WeekPickerProps, RangePickerProps } from './props';
const WrappedRangePicker = wrapPicker(RangePicker, RangePickerProps, 'date');
const WrappedWeekPicker = wrapPicker(WeekPicker, WeekPickerProps, 'week');
const DatePicker = wrapPicker(createPicker(VcCalendar, DatePickerProps, 'ADatePicker'), DatePickerProps, 'date');
const MonthPicker = wrapPicker(createPicker(MonthCalendar, MonthPickerProps, 'AMonthPicker'), MonthPickerProps, 'month');
Object.assign(DatePicker, {
    RangePicker: WrappedRangePicker,
    MonthPicker,
    WeekPicker: WrappedWeekPicker,
});
/* istanbul ignore next */
DatePicker.install = function (app) {
    app.component(DatePicker.name, DatePicker);
    app.component(DatePicker.RangePicker.name, DatePicker.RangePicker);
    app.component(DatePicker.MonthPicker.name, DatePicker.MonthPicker);
    app.component(DatePicker.WeekPicker.name, DatePicker.WeekPicker);
    return app;
};
export default DatePicker;
