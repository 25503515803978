var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Text from './Text';
import Title from './Title';
import Paragraph from './Paragraph';
import PropTypes from '../_util/vue-types';
import { defineComponent } from 'vue';
import useConfigInject from '../_util/hooks/useConfigInject';
import Link from './Link';
import Base from './Base';
import classNames from '../_util/classNames';
const Typography = defineComponent({
    name: 'ATypography',
    Base,
    Text,
    Title,
    Paragraph,
    Link,
    inheritAttrs: false,
    setup(props, { slots, attrs }) {
        const { prefixCls } = useConfigInject('typography', props);
        return () => {
            var _a;
            const _b = Object.assign(Object.assign({}, props), attrs), { prefixCls: _prefixCls, class: _className, component: Component = 'article' } = _b, restProps = __rest(_b, ["prefixCls", "class", "component"]);
            return (<Component class={classNames(prefixCls.value, attrs.class)} {...restProps}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </Component>);
        };
    },
});
Typography.props = {
    prefixCls: PropTypes.string,
    component: PropTypes.string,
};
Typography.install = function (app) {
    app.component(Typography.name, Typography);
    app.component(Typography.Text.displayName, Text);
    app.component(Typography.Title.displayName, Title);
    app.component(Typography.Paragraph.displayName, Paragraph);
    app.component(Typography.Link.displayName, Link);
    return app;
};
export default Typography;
