import { defineComponent, inject } from 'vue';
import { Item, itemProps } from '../vc-menu';
import { getOptionProps, getSlot } from '../_util/props-util';
import Tooltip from '../tooltip';
import { injectExtraPropsKey } from '../vc-menu/FunctionProvider';
import PropTypes from '../_util/vue-types';
export default defineComponent({
    name: 'MenuItem',
    inheritAttrs: false,
    props: Object.assign(Object.assign({}, itemProps), { onClick: PropTypes.func }),
    isMenuItem: true,
    setup() {
        return {
            getInlineCollapsed: inject('getInlineCollapsed', () => false),
            layoutSiderContext: inject('layoutSiderContext', {}),
            injectExtraProps: inject(injectExtraPropsKey, () => ({})),
        };
    },
    methods: {
        onKeyDown(e) {
            this.$refs.menuItem.onKeyDown(e);
        },
    },
    render() {
        const props = getOptionProps(this);
        const { level, title, rootPrefixCls } = Object.assign(Object.assign({}, props), this.injectExtraProps);
        const { getInlineCollapsed, $attrs: attrs } = this;
        const inlineCollapsed = getInlineCollapsed();
        let tooltipTitle = title;
        const children = getSlot(this);
        if (typeof title === 'undefined') {
            tooltipTitle = level === 1 ? children : '';
        }
        else if (title === false) {
            tooltipTitle = '';
        }
        const tooltipProps = {
            title: tooltipTitle,
        };
        const siderCollapsed = this.layoutSiderContext.sCollapsed;
        if (!siderCollapsed && !inlineCollapsed) {
            tooltipProps.title = null;
            // Reset `visible` to fix control mode tooltip display not correct
            // ref: https://github.com/ant-design/ant-design/issues/16742
            tooltipProps.visible = false;
        }
        const itemProps = Object.assign(Object.assign(Object.assign(Object.assign({}, props), { title }), attrs), { ref: 'menuItem' });
        const toolTipProps = Object.assign(Object.assign({}, tooltipProps), { placement: 'right', overlayClassName: `${rootPrefixCls}-inline-collapsed-tooltip` });
        const item = <Item {...itemProps}>{children}</Item>;
        return <Tooltip {...toolTipProps}>{item}</Tooltip>;
    },
});
