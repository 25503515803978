import { createVNode, defineComponent, inject, provide, toRefs, ref, } from 'vue';
import PropTypes from '../_util/vue-types';
import classNames from '../_util/classNames';
import { defaultConfigProvider } from '../config-provider';
import { flattenChildren } from '../_util/props-util';
export const basicProps = {
    prefixCls: PropTypes.string,
    hasSider: PropTypes.looseBool,
    tagName: PropTypes.string,
};
function generator({ suffixCls, tagName, name }) {
    return (BasicComponent) => {
        const Adapter = defineComponent({
            name,
            setup(props, { slots }) {
                const { getPrefixCls } = inject('configProvider', defaultConfigProvider);
                return () => {
                    var _a;
                    const { prefixCls: customizePrefixCls } = props;
                    const prefixCls = getPrefixCls(suffixCls, customizePrefixCls);
                    const basicComponentProps = Object.assign(Object.assign({ prefixCls }, props), { tagName });
                    return (<BasicComponent {...basicComponentProps}>
              {flattenChildren((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots))}
            </BasicComponent>);
                };
            },
        });
        Adapter.props = basicProps;
        return Adapter;
    };
}
const Basic = defineComponent({
    props: basicProps,
    setup(props, { slots }) {
        const { prefixCls, tagName } = toRefs(props);
        return () => { var _a; return createVNode(tagName.value, { class: prefixCls.value }, (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)); };
    },
});
const BasicLayout = defineComponent({
    props: basicProps,
    setup(props, { slots }) {
        const siders = ref([]);
        const siderHookProvider = {
            addSider: id => {
                siders.value = [...siders.value, id];
            },
            removeSider: id => {
                siders.value = siders.value.filter(currentId => currentId !== id);
            },
        };
        provide('siderHook', siderHookProvider);
        return () => {
            var _a;
            const { prefixCls, hasSider, tagName } = props;
            const divCls = classNames(prefixCls, {
                [`${prefixCls}-has-sider`]: typeof hasSider === 'boolean' ? hasSider : siders.value.length > 0,
            });
            return createVNode(tagName, { class: divCls }, (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
        };
    },
});
const Layout = generator({
    suffixCls: 'layout',
    tagName: 'section',
    name: 'ALayout',
})(BasicLayout);
const Header = generator({
    suffixCls: 'layout-header',
    tagName: 'header',
    name: 'ALayoutHeader',
})(Basic);
const Footer = generator({
    suffixCls: 'layout-footer',
    tagName: 'footer',
    name: 'ALayoutFooter',
})(Basic);
const Content = generator({
    suffixCls: 'layout-content',
    tagName: 'main',
    name: 'ALayoutContent',
})(Basic);
Layout.Header = Header;
Layout.Footer = Footer;
Layout.Content = Content;
export default Layout;
