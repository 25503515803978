import { inject, defineComponent } from 'vue';
import classNames from '../_util/classNames';
import PropTypes from '../_util/vue-types';
import { defaultConfigProvider } from '../config-provider';
function parseFlex(flex) {
    if (typeof flex === 'number') {
        return `${flex} ${flex} auto`;
    }
    if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
        return `0 0 ${flex}`;
    }
    return flex;
}
const ACol = defineComponent({
    name: 'ACol',
    setup(props, { slots }) {
        const configProvider = inject('configProvider', defaultConfigProvider);
        const rowContext = inject('rowContext', {});
        return () => {
            var _a;
            const { gutter } = rowContext;
            const { prefixCls: customizePrefixCls, span, order, offset, push, pull, flex } = props;
            const prefixCls = configProvider.getPrefixCls('col', customizePrefixCls);
            let sizeClassObj = {};
            ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].forEach(size => {
                let sizeProps = {};
                const propSize = props[size];
                if (typeof propSize === 'number') {
                    sizeProps.span = propSize;
                }
                else if (typeof propSize === 'object') {
                    sizeProps = propSize || {};
                }
                sizeClassObj = Object.assign(Object.assign({}, sizeClassObj), { [`${prefixCls}-${size}-${sizeProps.span}`]: sizeProps.span !== undefined, [`${prefixCls}-${size}-order-${sizeProps.order}`]: sizeProps.order || sizeProps.order === 0, [`${prefixCls}-${size}-offset-${sizeProps.offset}`]: sizeProps.offset || sizeProps.offset === 0, [`${prefixCls}-${size}-push-${sizeProps.push}`]: sizeProps.push || sizeProps.push === 0, [`${prefixCls}-${size}-pull-${sizeProps.pull}`]: sizeProps.pull || sizeProps.pull === 0 });
            });
            const classes = classNames(prefixCls, {
                [`${prefixCls}-${span}`]: span !== undefined,
                [`${prefixCls}-order-${order}`]: order,
                [`${prefixCls}-offset-${offset}`]: offset,
                [`${prefixCls}-push-${push}`]: push,
                [`${prefixCls}-pull-${pull}`]: pull,
            }, sizeClassObj);
            let mergedStyle = {};
            if (gutter) {
                mergedStyle = Object.assign(Object.assign(Object.assign({}, (gutter[0] > 0
                    ? {
                        paddingLeft: `${gutter[0] / 2}px`,
                        paddingRight: `${gutter[0] / 2}px`,
                    }
                    : {})), (gutter[1] > 0
                    ? {
                        paddingTop: `${gutter[1] / 2}px`,
                        paddingBottom: `${gutter[1] / 2}px`,
                    }
                    : {})), mergedStyle);
            }
            if (flex) {
                mergedStyle.flex = parseFlex(flex);
            }
            return (<div class={classes} style={mergedStyle}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </div>);
        };
    },
});
const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
export const ColSize = PropTypes.shape({
    span: stringOrNumber,
    order: stringOrNumber,
    offset: stringOrNumber,
    push: stringOrNumber,
    pull: stringOrNumber,
}).loose;
const objectOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number, ColSize]);
ACol.props = {
    span: stringOrNumber,
    order: stringOrNumber,
    offset: stringOrNumber,
    push: stringOrNumber,
    pull: stringOrNumber,
    xs: objectOrNumber,
    sm: objectOrNumber,
    md: objectOrNumber,
    lg: objectOrNumber,
    xl: objectOrNumber,
    xxl: objectOrNumber,
    prefixCls: PropTypes.string,
    flex: stringOrNumber,
};
export default ACol;
