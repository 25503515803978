var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, nextTick, Transition as T, TransitionGroup as TG } from 'vue';
import { findDOMNode } from './props-util';
export const getTransitionProps = (transitionName, opt = {}) => {
    if (process.env.NODE_ENV === 'test') {
        return opt;
    }
    const transitionProps = transitionName
        ? Object.assign({ appear: true, appearFromClass: `${transitionName}-appear ${transitionName}-appear-prepare`, 
            // appearActiveClass: `antdv-base-transtion`,
            appearToClass: `${transitionName}-appear ${transitionName}-appear-active`, enterFromClass: `${transitionName}-enter ${transitionName}-enter-prepare`, 
            // enterActiveClass: `antdv-base-transtion`,
            enterToClass: `${transitionName}-enter ${transitionName}-enter-active`, leaveFromClass: ` ${transitionName}-leave`, leaveActiveClass: `${transitionName}-leave ${transitionName}-leave-active`, leaveToClass: `${transitionName}-leave ${transitionName}-leave-active` }, opt) : Object.assign({ css: false }, opt);
    return transitionProps;
};
export const getTransitionGroupProps = (transitionName, opt = {}) => {
    const transitionProps = transitionName
        ? Object.assign({ appear: true, appearFromClass: `${transitionName}-appear ${transitionName}-appear-prepare`, appearActiveClass: `${transitionName}`, appearToClass: `${transitionName}-appear ${transitionName}-appear-active`, enterFromClass: `${transitionName}-appear ${transitionName}-enter ${transitionName}-appear-prepare ${transitionName}-enter-prepare`, enterActiveClass: `${transitionName}`, enterToClass: `${transitionName}-enter ${transitionName}-appear ${transitionName}-appear-active ${transitionName}-enter-active`, leaveActiveClass: `${transitionName} ${transitionName}-leave`, leaveToClass: `${transitionName}-leave-active` }, opt) : Object.assign({ css: false }, opt);
    return transitionProps;
};
let Transition = T;
let TransitionGroup = TG;
if (process.env.NODE_ENV === 'test') {
    Transition = (props, { slots }) => {
        var _a, _b;
        const child = (_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)[0];
        if (child && child.dirs && child.dirs[0]) {
            const value = child.dirs[0].value;
            const oldValue = child.dirs[0].oldValue;
            if (!value && value !== oldValue) {
                nextTick(() => {
                    if (props.onAfterLeave) {
                        props.onAfterLeave(findDOMNode(this));
                    }
                });
            }
        }
        return (_b = slots.default) === null || _b === void 0 ? void 0 : _b.call(slots);
    };
    Transition.displayName = 'TransitionForTest';
    Transition.inheritAttrs = false;
    TransitionGroup = defineComponent({
        name: 'TransitionGroupForTest',
        inheritAttrs: false,
        props: ['tag', 'class'],
        setup(props, { slots }) {
            return () => {
                var _a;
                const { tag: Tag } = props, rest = __rest(props, ["tag"]);
                const children = ((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)) || [];
                if (Tag) {
                    return <Tag {...rest}>{children}</Tag>;
                }
                else {
                    return children;
                }
            };
        },
    });
}
export { Transition, TransitionGroup };
export default Transition;
