import { inject, defineComponent } from 'vue';
import classNames from '../_util/classNames';
import { defaultConfigProvider } from '../config-provider';
import PropTypes from '../_util/vue-types';
const CheckableTag = defineComponent({
    name: 'ACheckableTag',
    props: {
        prefixCls: PropTypes.string,
        checked: PropTypes.looseBool,
        onChange: {
            type: Function,
        },
        onClick: {
            type: Function,
        },
    },
    emits: ['update:checked', 'change', 'click'],
    setup(props, { slots, emit }) {
        const { getPrefixCls } = inject('configProvider', defaultConfigProvider);
        const handleClick = (e) => {
            const { checked } = props;
            emit('update:checked', !checked);
            emit('change', !checked);
            emit('click', e);
        };
        return () => {
            var _a;
            const { checked, prefixCls: customizePrefixCls } = props;
            const prefixCls = getPrefixCls('tag', customizePrefixCls);
            const cls = classNames(prefixCls, {
                [`${prefixCls}-checkable`]: true,
                [`${prefixCls}-checkable-checked`]: checked,
            });
            return (<span class={cls} onClick={handleClick}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </span>);
        };
    },
});
export default CheckableTag;
