import { defineComponent, inject } from 'vue';
import { defaultConfigProvider } from '../config-provider';
import ImageInternal from '../vc-image';
import { ImageProps } from '../vc-image/src/Image';
import PreviewGroup from './PreviewGroup';
const Image = defineComponent({
    name: 'AImage',
    inheritAttrs: false,
    props: ImageProps,
    setup(props, ctx) {
        const { slots, attrs } = ctx;
        const configProvider = inject('configProvider', defaultConfigProvider);
        return () => {
            const { getPrefixCls } = configProvider;
            const prefixCls = getPrefixCls('image', props.prefixCls);
            return <ImageInternal {...Object.assign(Object.assign(Object.assign({}, attrs), props), { prefixCls })} v-slots={slots}></ImageInternal>;
        };
    },
});
export { ImageProps };
Image.PreviewGroup = PreviewGroup;
Image.install = function (app) {
    app.component(Image.name, Image);
    app.component(Image.PreviewGroup.name, Image.PreviewGroup);
    return app;
};
export default Image;
