import { defineComponent, inject } from 'vue';
import { SubMenu as VcSubMenu } from '../vc-menu';
import classNames from '../_util/classNames';
import { injectExtraPropsKey } from '../vc-menu/FunctionProvider';
export default defineComponent({
    name: 'ASubMenu',
    isSubMenu: true,
    inheritAttrs: false,
    props: Object.assign({}, VcSubMenu.props),
    setup() {
        return {
            menuPropsContext: inject('menuPropsContext', {}),
            injectExtraProps: inject(injectExtraPropsKey, () => ({})),
        };
    },
    methods: {
        onKeyDown(e) {
            this.$refs.subMenu.onKeyDown(e);
        },
    },
    render() {
        const { $slots, $attrs } = this;
        const { rootPrefixCls, popupClassName } = Object.assign(Object.assign({}, this.$props), this.injectExtraProps);
        const { theme: antdMenuTheme } = this.menuPropsContext;
        const props = Object.assign(Object.assign(Object.assign({}, this.$props), { popupClassName: classNames(`${rootPrefixCls}-${antdMenuTheme}`, popupClassName), ref: 'subMenu' }), $attrs);
        return <VcSubMenu {...props} v-slots={$slots}></VcSubMenu>;
    },
});
