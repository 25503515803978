var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { inject, defineComponent } from 'vue';
import omit from 'omit.js';
import PropTypes from '../_util/vue-types';
import { getOptionProps, getComponent } from '../_util/props-util';
import { defaultConfigProvider } from '../config-provider';
import VcRate from '../vc-rate';
import StarFilled from '@ant-design/icons-vue/StarFilled';
import Tooltip from '../tooltip';
import { withInstall } from '../_util/type';
export const RateProps = {
    prefixCls: PropTypes.string,
    count: PropTypes.number,
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    allowHalf: PropTypes.looseBool,
    allowClear: PropTypes.looseBool,
    tooltips: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.looseBool,
    character: PropTypes.any,
    autofocus: PropTypes.looseBool,
};
const Rate = defineComponent({
    name: 'ARate',
    props: RateProps,
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    methods: {
        characterRender(node, { index }) {
            const { tooltips } = this.$props;
            if (!tooltips)
                return node;
            return <Tooltip title={tooltips[index]}>{node}</Tooltip>;
        },
        focus() {
            this.$refs.refRate.focus();
        },
        blur() {
            this.$refs.refRate.blur();
        },
    },
    render() {
        const _a = getOptionProps(this), { prefixCls: customizePrefixCls } = _a, restProps = __rest(_a, ["prefixCls"]);
        const { getPrefixCls } = this.configProvider;
        const prefixCls = getPrefixCls('rate', customizePrefixCls);
        const character = getComponent(this, 'character') || <StarFilled />;
        const rateProps = Object.assign(Object.assign(Object.assign({ character, characterRender: this.characterRender, prefixCls }, omit(restProps, ['tooltips'])), this.$attrs), { ref: 'refRate' });
        return <VcRate {...rateProps}/>;
    },
});
export default withInstall(Rate);
