import { flattenChildren } from '../_util/props-util';
import { computed, defineComponent, inject } from 'vue';
import { defaultConfigProvider } from '../config-provider';
import { withInstall } from '../_util/type';
const Divider = defineComponent({
    name: 'ADivider',
    props: {
        prefixCls: String,
        type: {
            type: String,
            default: 'horizontal',
        },
        dashed: {
            type: Boolean,
            default: false,
        },
        orientation: {
            type: String,
            default: 'center',
        },
    },
    setup(props, { slots }) {
        const { getPrefixCls } = inject('configProvider', defaultConfigProvider);
        const prefixCls = computed(() => getPrefixCls('divider', props.prefixCls));
        const classString = computed(() => {
            const { type, dashed, orientation } = props;
            const orientationPrefix = orientation.length > 0 ? '-' + orientation : orientation;
            const prefixClsRef = prefixCls.value;
            return {
                [prefixClsRef]: true,
                [`${prefixClsRef}-${type}`]: true,
                [`${prefixClsRef}-with-text${orientationPrefix}`]: slots.default,
                [`${prefixClsRef}-dashed`]: !!dashed,
            };
        });
        return () => {
            var _a;
            const children = flattenChildren((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots));
            return (<div class={classString.value} role="separator">
          {children.length ? <span class={`${prefixCls.value}-inner-text`}>{children}</span> : null}
        </div>);
        };
    },
});
export default withInstall(Divider);
