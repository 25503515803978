var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import { defaultConfigProvider } from '../config-provider';
import PropTypes from '../_util/vue-types';
import { getSlot } from '../_util/props-util';
export default defineComponent({
    name: 'ABreadcrumbSeparator',
    __ANT_BREADCRUMB_SEPARATOR: true,
    inheritAttrs: false,
    props: {
        prefixCls: PropTypes.string,
    },
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    render() {
        const { prefixCls: customizePrefixCls } = this;
        const _a = this.$attrs, { separator, class: className } = _a, restAttrs = __rest(_a, ["separator", "class"]);
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);
        const children = getSlot(this);
        return (<span class={[`${prefixCls}-separator`, className]} {...restAttrs}>
        {children.length > 0 ? children : '/'}
      </span>);
    },
});
