import { cloneElement } from '../../_util/vnode';
import { defineComponent, getCurrentInstance, inject, onMounted, withDirectives, } from 'vue';
import PropTypes from '../../_util/vue-types';
import antInput from '../../_util/antInputDirective';
const Input = defineComponent({
    name: 'Input',
    inheritAttrs: false,
    setup(props) {
        if (process.env.NODE_ENV === 'test') {
            onMounted(() => {
                const ins = getCurrentInstance();
                if (props.autofocus) {
                    if (ins.vnode && ins.vnode.el) {
                        ins.vnode.el.focus();
                    }
                }
            });
        }
        return {
            blurTimeout: null,
            VCSelectContainerEvent: inject('VCSelectContainerEvent'),
        };
    },
    render() {
        const { prefixCls, id, inputElement, disabled, tabindex, autofocus, autocomplete, editable, accessibilityIndex, value, onKeydown, onMousedown, onChange, onPaste, onCompositionstart, onCompositionend, open, inputRef, attrs, } = this.$props;
        let inputNode = withDirectives((inputElement || <input />), [[antInput]]);
        const inputProps = inputNode.props || {};
        const { onKeydown: onOriginKeyDown, onInput: onOriginInput, onMousedown: onOriginMouseDown, onCompositionstart: onOriginCompositionStart, onCompositionend: onOriginCompositionEnd, style, } = inputProps;
        inputNode = cloneElement(inputNode, Object.assign(Object.assign(Object.assign({ id, ref: inputRef, disabled,
            tabindex, autocomplete: autocomplete || 'off', autofocus, class: `${prefixCls}-selection-search-input`, style: Object.assign(Object.assign({}, style), { opacity: editable ? null : 0 }), role: 'combobox', 'aria-expanded': open, 'aria-haspopup': 'listbox', 'aria-owns': `${id}_list`, 'aria-autocomplete': 'list', 'aria-controls': `${id}_list`, 'aria-activedescendant': `${id}_list_${accessibilityIndex}` }, attrs), { value: editable ? value : '', readonly: !editable, unselectable: !editable ? 'on' : null, onKeydown: (event) => {
                onKeydown(event);
                if (onOriginKeyDown) {
                    onOriginKeyDown(event);
                }
            }, onMousedown: (event) => {
                onMousedown(event);
                if (onOriginMouseDown) {
                    onOriginMouseDown(event);
                }
            }, onInput: (event) => {
                onChange(event);
                if (onOriginInput) {
                    onOriginInput(event);
                }
            }, onCompositionstart(event) {
                onCompositionstart(event);
                if (onOriginCompositionStart) {
                    onOriginCompositionStart(event);
                }
            },
            onCompositionend(event) {
                onCompositionend(event);
                if (onOriginCompositionEnd) {
                    onOriginCompositionEnd(event);
                }
            }, onPaste, onFocus: (...args) => {
                var _a;
                clearTimeout(this.blurTimeout);
                (_a = this.VCSelectContainerEvent) === null || _a === void 0 ? void 0 : _a.focus(args[0]);
            }, onBlur: (...args) => {
                this.blurTimeout = setTimeout(() => {
                    var _a;
                    (_a = this.VCSelectContainerEvent) === null || _a === void 0 ? void 0 : _a.blur(args[0]);
                }, 200);
            } }), inputNode.type === 'textarea' ? {} : { type: 'search' }), true, true);
        return inputNode;
    },
});
Input.props = {
    inputRef: PropTypes.any,
    prefixCls: PropTypes.string,
    id: PropTypes.string,
    inputElement: PropTypes.any,
    disabled: PropTypes.looseBool,
    autofocus: PropTypes.looseBool,
    autocomplete: PropTypes.string,
    editable: PropTypes.looseBool,
    accessibilityIndex: PropTypes.number,
    value: PropTypes.string,
    open: PropTypes.looseBool,
    tabindex: PropTypes.number,
    /** Pass accessibility props to input */
    attrs: PropTypes.object,
    onKeydown: PropTypes.func,
    onMousedown: PropTypes.func,
    onChange: PropTypes.func,
    onPaste: PropTypes.func,
    onCompositionstart: PropTypes.func,
    onCompositionend: PropTypes.func,
};
export default Input;
