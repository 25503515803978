import { inject, provide, reactive, defineComponent, ref, onMounted, onBeforeUnmount, } from 'vue';
import classNames from '../_util/classNames';
import { tuple } from '../_util/type';
import PropTypes from '../_util/vue-types';
import { defaultConfigProvider } from '../config-provider';
import ResponsiveObserve, { responsiveArray, } from '../_util/responsiveObserve';
const RowAligns = tuple('top', 'middle', 'bottom', 'stretch');
const RowJustify = tuple('start', 'end', 'center', 'space-around', 'space-between');
const ARow = defineComponent({
    name: 'ARow',
    setup(props, { slots }) {
        const rowContext = reactive({
            gutter: undefined,
        });
        provide('rowContext', rowContext);
        let token;
        onMounted(() => {
            token = ResponsiveObserve.subscribe(screen => {
                const currentGutter = props.gutter || 0;
                if ((!Array.isArray(currentGutter) && typeof currentGutter === 'object') ||
                    (Array.isArray(currentGutter) &&
                        (typeof currentGutter[0] === 'object' || typeof currentGutter[1] === 'object'))) {
                    screens.value = screen;
                }
            });
        });
        onBeforeUnmount(() => {
            ResponsiveObserve.unsubscribe(token);
        });
        const screens = ref({
            xs: true,
            sm: true,
            md: true,
            lg: true,
            xl: true,
            xxl: true,
        });
        const { getPrefixCls } = inject('configProvider', defaultConfigProvider);
        const getGutter = () => {
            const results = [0, 0];
            const { gutter = 0 } = props;
            const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];
            normalizedGutter.forEach((g, index) => {
                if (typeof g === 'object') {
                    for (let i = 0; i < responsiveArray.length; i++) {
                        const breakpoint = responsiveArray[i];
                        if (screens.value[breakpoint] && g[breakpoint] !== undefined) {
                            results[index] = g[breakpoint];
                            break;
                        }
                    }
                }
                else {
                    results[index] = g || 0;
                }
            });
            return results;
        };
        return () => {
            var _a;
            const { prefixCls: customizePrefixCls, justify, align } = props;
            const prefixCls = getPrefixCls('row', customizePrefixCls);
            const gutter = getGutter();
            const classes = classNames(prefixCls, {
                [`${prefixCls}-${justify}`]: justify,
                [`${prefixCls}-${align}`]: align,
            });
            const rowStyle = Object.assign(Object.assign({}, (gutter[0] > 0
                ? {
                    marginLeft: `${gutter[0] / -2}px`,
                    marginRight: `${gutter[0] / -2}px`,
                }
                : {})), (gutter[1] > 0
                ? {
                    marginTop: `${gutter[1] / -2}px`,
                    marginBottom: `${gutter[1] / -2}px`,
                }
                : {}));
            rowContext.gutter = gutter;
            return (<div class={classes} style={rowStyle}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </div>);
        };
    },
});
ARow.props = {
    type: PropTypes.oneOf(['flex']),
    align: PropTypes.oneOf(RowAligns),
    justify: PropTypes.oneOf(RowJustify),
    prefixCls: PropTypes.string,
    gutter: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.array]).def(0),
};
export default ARow;
